import { styled } from "@grupoboticario/flora";
import { Box, Text } from "@grupoboticario/flora-react";

export const DetailsContainer = styled(Box, {
  display: "flex",
  flexDirection: "column",
  gap: "$6",
}) as typeof Box;

export const AttributeBox = styled(Box, {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "$backgroundPrimary",
  borderRadius: "$medium",
  padding: "$2 $8",
}) as typeof Box;

export const AttributeLabel = styled(Box, {
  color: "$nonInteractivePredominant",
  fontSize: "$bodySmall",
  fontWeight: "$medium",
  lineHeight: "$bodySmallStandard",
}) as typeof Box;

export const AttributeValue = styled(Box, {
  fontSize: "$bodyLarge",
  fontWeight: "$medium",
  lineHeight: "$bodyLargeStandard",
  color: "$nonInteractivePredominant",
}) as typeof Box;

export const Status = styled(Text, {
  display: "flex",
  fontSize: "$bodyLarge",
  fontWeight: "$medium",
  lineHeight: "$bodyLargeStandard",
  color: "$nonInteractivePredominant",

  "&::before": {
    content: " ",
    width: "$1",
    height: "$6",
    marginRight: "$2",
    borderRadius: "$large",
  },

  defaultVariants: {
    active: true,
  },

  variants: {
    active: {
      true: {
        "&::before": {
          backgroundColor: "$success-standard",
        },
      },
      false: {
        "&::before": {
          backgroundColor: "$nonInteractiveAltAuxiliar",
        },
      },
    },
  },
});
