import { Box, Flex } from "@grupoboticario/flora-react";
import {
  AttributeBox,
  AttributeLabel,
  AttributeValue,
  DetailsContainer,
  Status,
} from "./Details.styles";
import { Teams } from "../Teams";
import { useParams } from "react-router-dom";
import { useUserDetails } from "./useUserDetails";
import { useMemo } from "react";

export function Details() {
  const { userId } = useParams();
  const { data: user, isError, isLoading } = useUserDetails(userId);

  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("pt-BR"), []);
  const dateTime = useMemo(
    () =>
      new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      }),
    [],
  );

  if (isLoading) {
    return <div>Carregando detalhes do usuário...</div>;
  }

  if (isError) {
    return <div>Erro ao carregar os detalhes do usuário</div>;
  }
  return (
    <DetailsContainer>
      <AttributeBox id="name">
        <Box>
          <AttributeLabel>Nome</AttributeLabel>
        </Box>
        <Box>
          <AttributeValue>{user.name}</AttributeValue>
        </Box>
      </AttributeBox>

      <Box
        id="identity-section"
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "$3",
        }}
      >
        <Box
          id="identity-section-header"
          css={{
            padding: "$0 $4",
          }}
        >
          <AttributeLabel>Identificação</AttributeLabel>
        </Box>
        <Box
          id="identity-section-content"
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "$1",
          }}
        >
          <Box
            className="identity-section-content-row"
            css={{
              display: "flex",
              gap: "$1",
            }}
          >
            <AttributeBox css={{ width: "50%" }}>
              <Box>
                <AttributeLabel>E-mail</AttributeLabel>
              </Box>
              <Box>
                <AttributeValue>{user.email}</AttributeValue>
              </Box>
            </AttributeBox>

            <AttributeBox css={{ width: "30%" }}>
              <Box>
                <AttributeLabel>Código VD+</AttributeLabel>
              </Box>
              <Box>
                <AttributeValue>
                  {!user.geraId ? "--" : user.geraId}
                </AttributeValue>
              </Box>
            </AttributeBox>

            <AttributeBox css={{ width: "20%" }}>
              <Box>
                <AttributeLabel>Status</AttributeLabel>
              </Box>
              <Box>
                <Status active={user.active}>
                  {user.active ? "Ativo" : "Inativo"}
                </Status>
              </Box>
            </AttributeBox>
          </Box>

          <Box
            className="identity-section-content-row"
            css={{
              display: "flex",
              gap: "$1",
            }}
          >
            <AttributeBox css={{ width: "50%" }}>
              <Box>
                <AttributeLabel>Data de criação</AttributeLabel>
              </Box>
              <Box>
                <AttributeValue>
                  {user.createdAt
                    ? dateFormatter.format(new Date(user.createdAt))
                    : "--"}
                </AttributeValue>
              </Box>
            </AttributeBox>

            <AttributeBox css={{ width: "50%" }}>
              <Box>
                <AttributeLabel>Último acesso</AttributeLabel>
              </Box>
              <Box>
                <AttributeValue>
                  {user.lastLoginAt
                    ? dateTime.format(new Date(user.lastLoginAt))
                    : "--"}
                </AttributeValue>
              </Box>
            </AttributeBox>
          </Box>
        </Box>
      </Box>
      <Box
        id="teams-section"
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "$3",
        }}
      >
        <Box
          id="teams-section-header"
          css={{
            padding: "$0 $4",
          }}
        >
          <AttributeLabel>Equipes que é membro</AttributeLabel>
        </Box>
        <Flex direction={"column"}>
          {user.groups.length === 0 ? (
            <Box
              css={{
                padding: "$0 $4",
              }}
            >
              <AttributeValue>{`Não existe equipes em que o usuário é membro.`}</AttributeValue>
            </Box>
          ) : (
            <Teams teams={user.groups} />
          )}
        </Flex>
      </Box>
    </DetailsContainer>
  );
}
