import { TableCell, TableHead, TableRoot } from "@shared/styles";
import { Flex } from "@grupoboticario/flora-react";
import { Table } from "@grupoboticario/flora-team-react-table";
import { useUserList } from "./useUserList";
import { useEffect, useMemo } from "react";
import { Paginate } from "@shared/components";
import { usePaginateCursor } from "@shared/hooks";
import { useNavigate } from "react-router-dom";
import { useSearch } from "@shared/components/Search/useSearch";
import { Status } from "@features/UserInfo/components/Details/Details.styles";

export function UserList() {
  const {
    page,
    direction,
    setNextPage,
    setPreviousPage,
    DefaultValueCursorReset,
  } = usePaginateCursor();
  const navigate = useNavigate();
  const { searchTerm } = useSearch();

  const {
    data: users,
    isError,
    isLoading,
    isPlaceholderData,
    isFetching,
  } = useUserList(searchTerm, { cursor: page, direction });

  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("pt-BR"), []);

  const hasMoreThanOnePage = !(
    !users?.meta?.hasNextPage && !users?.meta?.hasPrevPage
  );

  function handleUserClick(userId: string) {
    navigate(`/usuarios/${userId}`);
  }
  useEffect(() => {
    DefaultValueCursorReset();
  }, [searchTerm]);

  if (isLoading) {
    return <div>Carregando lista de usuários...</div>;
  }

  if (isError) {
    return <div>Erro ao carregar a lista de usuários</div>;
  }

  if (isFetching) {
    return <div>Carregando mais usuários...</div>;
  }

  return (
    <Flex css={{ flexDirection: "column", alignItems: "center", gap: "$6" }}>
      <TableRoot>
        <thead>
          <Table.Row>
            <TableHead align="left">Cód. VD+</TableHead>
            <TableHead>Nome</TableHead>
            <TableHead align="left">E-mail</TableHead>
            <TableHead align="left">Status</TableHead>
            <TableHead align="left">Data de cadastro</TableHead>
            <TableHead align="left">Último acesso</TableHead>
          </Table.Row>
        </thead>
        <tbody>
          {users.data?.map((user) => (
            <Table.Row
              key={user.id}
              onClick={() => handleUserClick(user.id)}
              css={{ cursor: "pointer" }}
            >
              <TableCell align="left">{user.extras.geraID}</TableCell>
              <TableCell align="left">{user.name}</TableCell>
              <TableCell align="left">{user.email}</TableCell>
              <TableCell align="left">
                <Status active={user.active}>
                  {user.active ? "Ativo" : "Inativo"}
                </Status>
              </TableCell>
              <TableCell align="left">
                {user.createdAt
                  ? dateFormatter.format(new Date(user.createdAt))
                  : "--"}
              </TableCell>
              <TableCell align="left">
                {user.lastLoginAt
                  ? dateFormatter.format(new Date(user.lastLoginAt))
                  : "--"}
              </TableCell>
            </Table.Row>
          ))}
        </tbody>
      </TableRoot>
      {hasMoreThanOnePage && (
        <Paginate
          nextPageDisabled={isPlaceholderData || !users.meta.hasNextPage}
          previousPageDisabled={!users.meta.hasPrevPage}
          meta={{
            next: () => {
              setNextPage(users.meta.next);
            },
            previous: () => {
              setPreviousPage(users.meta.previous);
            },
          }}
        />
      )}
    </Flex>
  );
}
