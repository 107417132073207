import { Box } from "@grupoboticario/flora-react";
import { Search as SearchFlora } from "@grupoboticario/flora-team-react-search";
import { useState } from "react";

interface SearchProps {
  placeholder: string;
  onSearchChange: (term: string) => void;
  onSelectOption: (option: string) => void;
  options?: string[];
  isLoading: boolean;
}

export function Search({
  placeholder,
  onSearchChange,
  onSelectOption,
  options,
  isLoading,
}: SearchProps) {
  const [searchTerm, setSearchTerm] = useState("");

  function handleSearchChange(term: string) {
    setSearchTerm(term);
    onSearchChange(term);
  }

  return (
    <>
      <Box
        css={{
          "@desktop": {
            width: "50rem",
          },
        }}
        data-testid="search-component"
      >
        <SearchFlora.Root>
          <SearchFlora.Trigger>
            <SearchFlora.Input
              placeholder={placeholder}
              value={searchTerm}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </SearchFlora.Trigger>
          {options && (
            <SearchFlora.Content
              open={searchTerm ? true : false}
              loading={isLoading}
              onClose={() => handleSearchChange("")}
              onSelect={(option) => onSelectOption(option)}
              options={options}
              value={searchTerm}
            />
          )}
        </SearchFlora.Root>
      </Box>
    </>
  );
}
