import { Box } from "@grupoboticario/flora-react";
import {
  Body,
  Card,
  Content,
  Header,
  TeamsContainer,
  Title,
} from "./Teams.styles";
import { Team } from "@shared/types";

interface TeamsProps {
  teams: Team[];
}

export function Teams({ teams }: TeamsProps) {
  return (
    <TeamsContainer>
      {teams?.map((team) => (
        <Card key={team.id}>
          <Header>
            <Title>{team.name}</Title>
          </Header>
          <Body>
            <Title>Responsável</Title>
            <Box
              css={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {team.responsibles.length === 0 ? (
                <Content>--</Content>
              ) : (
                team.responsibles.map((responsible) => (
                  <Content key={responsible.id}>{responsible.name}</Content>
                ))
              )}
            </Box>
          </Body>
        </Card>
      ))}
    </TeamsContainer>
  );
}
