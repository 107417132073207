import { Route, Routes } from "react-router-dom";
import { Team } from "./pages/Team";
import { Teams } from "./pages/Teams";
import { NewMember } from "./pages/NewMember";
import { Users } from "./pages/Users";

export function Router() {
  return (
    <Routes>
      <Route path="/">
        <Route path="equipes">
          <Route index element={<Teams />} />
          <Route path=":teamId">
            <Route index element={<Team />} />
            <Route path="integrantes">
              <Route path="cadastrar" element={<NewMember />} />
            </Route>
          </Route>
        </Route>
        <Route path="usuarios" element={<Users />} />
      </Route>
    </Routes>
  );
}
