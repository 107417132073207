import { BrowserRouter } from "react-router-dom";
import { Router } from "./Router";
import { FloraProvider } from "@grupoboticario/flora-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./services/reactQuery";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export function App() {
  return (
    <FloraProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename="/admin">
          <Router />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </FloraProvider>
  );
}
