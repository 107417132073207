import { Box, styled } from "@grupoboticario/flora-react";

export const TeamContainer = styled(Box, {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginTop: "$6",
  padding: "$6",
  gap: "$6",
  borderRadius: "$medium",
  borderWidth: "$hairline",
  backgroundColor: "$backgroundPrimary",
}) as typeof Box;

export const PageTitle = styled(Box, {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "$2",
  fontSize: "$subtitleDesk",
  fontWeight: "$medium",
}) as typeof Box;
