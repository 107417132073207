import { authService } from "@vdi/auth-service";

export async function fetchUsers(term: string) {
  const { token } = await authService.getTokenOrRedirect();

  const url = `${process.env.API_BASE_URL}/users?filter=${term}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`request response status error: ${response.status}`);
  }
  return response.json();
}
