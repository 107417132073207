import { Flex, Text } from "@grupoboticario/flora-react";
import { PageTitle, TeamContainer } from "../Team/Team.styles";
import { UsersList } from "@features/UsersList";

export function Users() {
  return (
    <Flex direction={"column"} css={{ margin: "$6" }}>
      <PageTitle>
        <Text>Usuários</Text>
      </PageTitle>
      <TeamContainer>
        <UsersList />
      </TeamContainer>
    </Flex>
  );
}
