import { mockUsers } from "@mocks/Users";
import { useQuery } from "@tanstack/react-query";

function fetchUsers() {
  return mockUsers;
}

export function useUsersList() {
  return useQuery({
    queryKey: ["users"],
    queryFn: () => fetchUsers(),
  });
}
