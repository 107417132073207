import { TableCell, TableHead, TableRoot } from "@shared/styles";
import { Flex } from "@grupoboticario/flora-react";
import { Table } from "@grupoboticario/flora-team-react-table";
import { useUsersList } from "./useUsersList";
import { useMemo } from "react";

export function UsersList() {
  const { data: users, isError, isLoading } = useUsersList();
  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("pt-BR"), []);

  if (isLoading) {
    return <div>Carregando lista de usuários...</div>;
  }

  if (isError) {
    return <div>Erro ao carregar a lista de usuários</div>;
  }

  return (
    <Flex css={{ flexDirection: "column", alignItems: "center", gap: "$6" }}>
      <TableRoot>
        <thead>
          <Table.Row>
            <TableHead>Nome</TableHead>
            <TableHead align="left">E-mail</TableHead>
            <TableHead align="left">Data de cadastro</TableHead>
          </Table.Row>
        </thead>
        <tbody>
          {users?.map((user) => (
            <Table.Row key={user.id} css={{ cursor: "pointer" }}>
              <TableCell align="left">{user.name}</TableCell>
              <TableCell align="left">{user.email}</TableCell>
              <TableCell align="left">
                {dateFormatter.format(new Date(user.createdAt))}
              </TableCell>
            </Table.Row>
          ))}
        </tbody>
      </TableRoot>
    </Flex>
  );
}
