import { Flex, Text } from "@grupoboticario/flora-react";
import { PageTitle, TeamContainer } from "../Team/Team.styles";
import { UserList } from "@features/UserList";
import { ActionBar } from "@features/UserList/components/ActionBar";
import { SearchProvider } from "@shared/components/Search/useSearch";

export function Users() {
  return (
    <Flex direction={"column"} css={{ margin: "$6" }}>
      <PageTitle>
        <Text>Usuários</Text>
      </PageTitle>
      <TeamContainer>
        <SearchProvider>
          <ActionBar />
          <UserList />
        </SearchProvider>
      </TeamContainer>
    </Flex>
  );
}
